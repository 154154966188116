import React from "react"

const ConfirmFavoriteProduct = () => {
  return (
    <div className={"confirm-fovarite-product"}>
      <p className={"confirm-fovarite-product__text pop-up__text"}>
        Вы уверены, что хотите сменить любимый товар?
      </p>
      <div className={"confirm-fovarite-product__actions pop-up__actions"}>
        <button className={"confirm-fovarite-product__button button button--red"}>Да</button>
        <button className={"confirm-fovarite-product__button button--outlined"}>Нет</button>
      </div>
    </div>
  )
}

export default ConfirmFavoriteProduct
