import React from "react"

// components
import ProductCard from "@components/cabinet/product-cards/ProductCard"

const CouponesPage = () => {
  const items = [1, 2, 3, 4, 44, 444, 2344, 44322, 34344, 4111, 433334, 43332222]

  return (
    <div className="list">
      {items.map((p) => (
        <ProductCard key={p} buttonText="Просрочен" isActiveButton={false} disabled />
      ))}
    </div>
  )
}

export default CouponesPage
