import React from "react"

const AuthActions = ({
  changeButtonLabel,
  changeFormType,
  actionButtonLabel,
  actionButtonDisabled,
  onAction,
}) => {
  return (
    <div className={"auth-form__actoins-wrapper"}>
      <button
        type="button"
        className={"auth-form__button-change-form button-inline"}
        onClick={changeFormType}
      >
        {changeButtonLabel}
      </button>
      <button
        type="submit"
        className={"auth-form__button-action button button--red"}
        disabled={actionButtonDisabled}
        onClick={onAction}
      >
        {actionButtonLabel}
      </button>
    </div>
  )
}

export default AuthActions
