import React from "react-dom"
import { Link } from "react-router-dom"

const Navigation = () => {
  return (
    <nav className="nav flex">
      <Link className="nav__item" to="/catalog-goods">
        Каталог
      </Link>
      <Link className="nav__item" to="/small-food">
        SMALL Food
      </Link>
      <Link className="nav__item" to="/catalog-goods">
        Покупателям
      </Link>
      <Link className="nav__item" to="/partners">
        Бизнесу
      </Link>
      <Link className="nav__item" to="/job">
        Работа
      </Link>
      <Link className="nav__item" to="/about">
        О компании
      </Link>
      <Link className="nav__item" to="/esf">
        Выписать ЭСФ
      </Link>
    </nav>
  )
}

export default Navigation
