import React from "react"

//components
import TransactionTable from "@components/cabinet/my-purchases/table/TransactionTable"

const MyPurchasesTransactionPage = () => {
  return <TransactionTable />
}

export default MyPurchasesTransactionPage
