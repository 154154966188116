import React from "react-dom"

function TheLogo(){
  return(
    <svg className="logo" viewBox="0 0 191 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.394531" y="0.5" width="190" height="45" rx="8" fill="#EA0000"/>
      <path d="M14.7188 26.5028L18.2698 26.1575C18.4835 27.3494 18.9151 28.2249 19.5645 28.7838C20.2221 29.3428 21.1057 29.6223 22.2154 29.6223C23.3909 29.6223 24.2745 29.3757 24.8664 28.8825C25.4664 28.3811 25.7665 27.7974 25.7665 27.1316C25.7665 26.7042 25.6391 26.3425 25.3842 26.0466C25.1376 25.7424 24.702 25.4794 24.0772 25.2574C23.6498 25.1095 22.6757 24.8464 21.155 24.4683C19.1987 23.9833 17.8259 23.3874 17.0368 22.6805C15.9271 21.6858 15.3722 20.4734 15.3722 19.0431C15.3722 18.1225 15.6312 17.2635 16.149 16.4661C16.6751 15.6606 17.4272 15.0482 18.4054 14.629C19.3918 14.2097 20.5796 14.0001 21.9688 14.0001C24.2375 14.0001 25.9432 14.4974 27.0858 15.4921C28.2366 16.4867 28.8407 17.8142 28.8983 19.4747L25.2486 19.6349C25.0924 18.7061 24.7554 18.0403 24.2375 17.6375C23.7279 17.2265 22.9593 17.021 21.9318 17.021C20.8714 17.021 20.0412 17.2388 19.4412 17.6745C19.0548 17.954 18.8616 18.328 18.8616 18.7965C18.8616 19.2239 19.0425 19.5897 19.4042 19.8939C19.8645 20.2802 20.9824 20.683 22.7579 21.1022C24.5335 21.5214 25.8446 21.9571 26.6912 22.4092C27.5461 22.8531 28.2119 23.4655 28.6887 24.2464C29.1737 25.0191 29.4162 25.9767 29.4162 27.1193C29.4162 28.155 29.1284 29.125 28.553 30.0292C27.9776 30.9334 27.1639 31.6074 26.1117 32.0513C25.0595 32.487 23.7484 32.7048 22.1784 32.7048C19.8933 32.7048 18.1383 32.1787 16.9135 31.1265C15.6887 30.0662 14.9571 28.5249 14.7188 26.5028Z" fill="white"/>
      <path d="M32.437 32.3842V14.3084H37.8992L41.179 26.6384L44.4218 14.3084H49.8963V32.3842H46.5056V18.1553L42.9175 32.3842H39.4035L35.8278 18.1553V32.3842H32.437Z" fill="white"/>
      <path d="M69.8217 32.3842H65.8514L64.2732 28.2783H57.0478L55.5558 32.3842H51.6842L58.7246 14.3084H62.5839L69.8217 32.3842ZM63.1018 25.2328L60.6111 18.5252L58.1698 25.2328H63.1018Z" fill="white"/>
      <path d="M71.8685 32.3842V14.4563H75.5182V29.3387H84.5931V32.3842H71.8685Z" fill="white"/>
      <path d="M87.3057 32.3842V14.4563H90.9554V29.3387H100.03V32.3842H87.3057Z" fill="white"/>
      <path d="M121.63 30.6185L119.953 32.759C119.131 32.3579 118.342 31.8055 117.586 31.1019C117 31.628 116.379 32.0159 115.721 32.2658C115.064 32.5157 114.281 32.6407 113.374 32.6407C111.572 32.6407 110.217 32.1343 109.31 31.1216C108.606 30.3391 108.254 29.4381 108.254 28.4189C108.254 27.4916 108.531 26.6598 109.083 25.9233C109.635 25.1802 110.461 24.5357 111.559 23.9899C111.066 23.4046 110.694 22.849 110.444 22.3229C110.194 21.7902 110.069 21.2872 110.069 20.8137C110.069 19.9391 110.418 19.1993 111.115 18.5943C111.819 17.9827 112.818 17.6769 114.114 17.6769C115.363 17.6769 116.34 17.9959 117.043 18.6337C117.747 19.2716 118.099 20.0509 118.099 20.9715C118.099 21.5568 117.924 22.1125 117.576 22.6385C117.227 23.1646 116.524 23.7598 115.465 24.4239L117.477 27.0774C117.714 26.6565 117.918 26.1074 118.089 25.4301L120.594 26.0022C120.344 26.8899 120.127 27.541 119.943 27.9553C119.759 28.363 119.562 28.7049 119.351 28.9811C119.66 29.2705 120.058 29.5894 120.545 29.9379C121.038 30.2799 121.4 30.5068 121.63 30.6185ZM114.084 22.7569L114.834 22.1848C115.386 21.7574 115.662 21.3332 115.662 20.9123C115.662 20.5572 115.527 20.2547 115.258 20.0048C114.995 19.755 114.636 19.63 114.183 19.63C113.742 19.63 113.397 19.7418 113.147 19.9654C112.897 20.1824 112.772 20.4356 112.772 20.7249C112.772 21.0669 112.983 21.4812 113.403 21.9678L114.084 22.7569ZM112.999 25.7654C112.361 26.0877 111.884 26.4756 111.569 26.9294C111.253 27.3831 111.095 27.8467 111.095 28.3202C111.095 28.9186 111.289 29.4086 111.677 29.79C112.072 30.1648 112.598 30.3522 113.255 30.3522C113.689 30.3522 114.107 30.2667 114.508 30.0957C114.909 29.9248 115.347 29.642 115.82 29.2474L112.999 25.7654Z" fill="white"/>
      <path d="M129.906 26.5028L133.457 26.1575C133.671 27.3494 134.102 28.2249 134.752 28.7838C135.409 29.3428 136.293 29.6223 137.403 29.6223C138.578 29.6223 139.462 29.3757 140.054 28.8825C140.654 28.3811 140.954 27.7974 140.954 27.1316C140.954 26.7042 140.826 26.3425 140.571 26.0466C140.325 25.7424 139.889 25.4794 139.264 25.2574C138.837 25.1095 137.863 24.8464 136.342 24.4683C134.386 23.9833 133.013 23.3874 132.224 22.6805C131.114 21.6858 130.559 20.4734 130.559 19.0431C130.559 18.1225 130.818 17.2635 131.336 16.4661C131.862 15.6606 132.614 15.0482 133.593 14.629C134.579 14.2097 135.767 14.0001 137.156 14.0001C139.425 14.0001 141.13 14.4974 142.273 15.4921C143.424 16.4867 144.028 17.8142 144.085 19.4747L140.436 19.6349C140.28 18.7061 139.943 18.0403 139.425 17.6375C138.915 17.2265 138.147 17.021 137.119 17.021C136.059 17.021 135.228 17.2388 134.628 17.6745C134.242 17.954 134.049 18.328 134.049 18.7965C134.049 19.2239 134.23 19.5897 134.591 19.8939C135.052 20.2802 136.17 20.683 137.945 21.1022C139.721 21.5214 141.032 21.9571 141.878 22.4092C142.733 22.8531 143.399 23.4655 143.876 24.2464C144.361 25.0191 144.603 25.9767 144.603 27.1193C144.603 28.155 144.316 29.125 143.74 30.0292C143.165 30.9334 142.351 31.6074 141.299 32.0513C140.247 32.487 138.936 32.7048 137.366 32.7048C135.08 32.7048 133.325 32.1787 132.101 31.1265C130.876 30.0662 130.144 28.5249 129.906 26.5028Z" fill="white"/>
      <path d="M147.526 32.3842V14.3084H150.99V23.9011L155.047 19.2897H159.313L154.837 24.0738L159.634 32.3842H155.898L152.606 26.5028L150.99 28.192V32.3842H147.526Z" fill="white"/>
      <path d="M161.705 17.5142V14.3084H165.17V17.5142H161.705ZM161.705 32.3842V19.2897H165.17V32.3842H161.705Z" fill="white"/>
      <path d="M167.217 19.2897H169.14V18.3033C169.14 17.2018 169.255 16.3798 169.485 15.8373C169.724 15.2948 170.155 14.855 170.78 14.518C171.413 14.1727 172.21 14.0001 173.172 14.0001C174.158 14.0001 175.124 14.1481 176.07 14.444L175.601 16.8607C175.05 16.7292 174.52 16.6634 174.01 16.6634C173.509 16.6634 173.147 16.7826 172.925 17.021C172.712 17.2511 172.605 17.6991 172.605 18.365V19.2897H175.194V22.0146H172.605V32.3842H169.14V22.0146H167.217V19.2897Z" fill="white"/>
    </svg>

  )
}

export default TheLogo;