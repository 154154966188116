import React from "react-dom"
import TheLogo from "@components/shared/icons/TheLogo"
import LanguageSwitcher from "@components/shared/LanguageSwitcher"
import CitySwitcher from "@components/shared/CitySwithcer"
import SearchBar from "@components/shared/SearchBar"
import ProfileButton from "@components/shared/ProfileButton"
import Navigation from "@components/shared/Navigation"
import AppBurger from "@components/shared/AppBurger"

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="header__top flex">
          <AppBurger className="header__burger-button" />

          <div className="header__top-item header__logo">
            <TheLogo />
          </div>
          <div className="header__top-item header__phone">
            <a className="header__phone-number flex" href="tel:88002000600">
              <span className="header__phone-number-icon"></span>
              <span>8 800 2000 600</span>
            </a>
          </div>
          <div className="header__top-item header__search">
            <SearchBar />
          </div>
          <div className="header__top-item header__settings flex">
            <CitySwitcher />
            <LanguageSwitcher />
          </div>
          <div className="header__top-item header__profile">
            <ProfileButton />
          </div>
        </div>
        <div className="header__bottom">
          <Navigation />
        </div>
      </div>
    </header>
  )
}

export default Header
