import React from "react"
import { useState } from "react"

//components
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm"
import ForgetPasswordForm from "./ForgetPasswordForm"

export default function AuthForm() {
  const [formType, setFormType] = useState("login")

  const handleChangeFormType = (type) => {
    setFormType(type)
  }

  switch (formType) {
    case "login":
      return <LoginForm changeFormType={handleChangeFormType} />
    case "signup":
      return <RegisterForm changeFormType={handleChangeFormType} />
    case "forget-password":
      return <ForgetPasswordForm changeFormType={handleChangeFormType} />
    default:
      return <LoginForm changeFormType={handleChangeFormType} />
  }
}
