import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"

//assets
import fovaritePlacholder from "@assets/images/cabinet/fovarite-placeholder.png"

const FavoriteCardDefault = ({ className }) => {
  const [isAvailableFovariteProduct, setIsAvailableFovariteProduct] = useState(false)

  useEffect(() => {
    setIsAvailableFovariteProduct(true)
  })

  return (
    /**
     *  IF status disabled need to add classes
     *  .disbaled for text
     *  .disabled-button for button element
     *
     **/

    <div className={`favorite-card flex ${className}`}>
      <img className="favorite-card__image-placeholder" src={fovaritePlacholder} alt="" />
      <div className="favorite-card__info-wrapper  flex">
        <h4 className={cn("favorite-card__label", !isAvailableFovariteProduct && "disabled")}>
          Выберите любимый товар
        </h4>

        {!isAvailableFovariteProduct && (
          <p className="favorite-card__text-disabled">
            Для активации скидки совершите покупку на 5000 тг
          </p>
        )}

        <Link to="/cabinet/favorite-products" className="button favorite-card__button button--red ">
          Выбрать
        </Link>
      </div>
    </div>
  )
}

export default FavoriteCardDefault
