import React from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import { toggleIsOpenAuthForm } from "@/store/slices/uiSlice"

//components
import PopUp from "@/components/shared/popup/PopUp"
import AuthForm from "@/components/cabinet/popup-content/auth/AuthForm"

export default function ProfileButton() {
  const isOpenAuthForm = useSelector((state) => state.uiSlice.isOpenAuthForm)
  const dispatch = useDispatch()

  const AuthFormPopUp = () => {
    return (
      <PopUp onClose={togglePopUp}>
        <AuthForm />
      </PopUp>
    )
  }

  const togglePopUp = () => {
    dispatch(toggleIsOpenAuthForm())
  }

  return (
    <>
      <button onClick={togglePopUp} href="" className="profile-icon icon"></button>
      {isOpenAuthForm && <AuthFormPopUp />}
    </>
  )
}
