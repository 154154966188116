import React from "react"

export default function CircleStatus({ isActive }) {
  const style = {
    backgroundColor: "#5ACF58",
  }

  if (!isActive) {
    style.backgroundColor = "#EA0000"
  }

  return (
    <>
      <span className="circle-status" style={style}></span>
      <span className="circle-status__text">{isActive ? "Активна" : "Неактивна"}</span>
    </>
  )
}
