import React from 'react-dom'

export default function CitySwitcher(){
  
  return(
          <button className="city-switcher flex">
              <span className='city-switcher__icon icon'></span>
              <span className='city-switcher__selected-value'>Алматы</span>
              <span className='icon icon-arrow-down'></span>
          </button>
        )
}