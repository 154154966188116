import React from "react"
import { toggleMenu } from "@/store/slices/uiSlice"
import { useDispatch } from "react-redux/es/exports"

export default function AppBurger({ className }) {
  const dispatch = useDispatch()

  return (
    <button className={`burger ${className}`} onClick={() => dispatch(toggleMenu())}>
      <span className="burger__line"></span>
      <span className="burger__line burger__line-middle"></span>
      <span className="burger__line"></span>
    </button>
  )
}
