import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

const Breadcrumb = () => {
  const location = useLocation()
  const [routes, setRoutes] = useState([])
  // const routeMap = {
  //   "/cabinet/profile": "Мой профиль",
  //   "/cabinet/small-card": "Карата small",
  //   "/cabinet/bonuses-coupons": "Бонусы и купоны",
  //   "/cabinet/my-purchases": "Мои покупки",
  //   "/cabinet/clubs": "Клубы по интересам",
  //   "/cabinet/contacts": "Контактная информация",
  // }

  const routeMap2 = {
    cabinet: {
      indexLabel: "Личный кабинет",
      profile: {
        indexLabel: "Мой профиль",
      },
      "small-card": {
        indexLabel: "Карата small",
      },
      "bonuses-coupons": {
        indexLabel: "Бонусы и купоны",
        all: "Все купоны",
        my: "Мои купоны",
        archive: "Архив",
      },
      "my-purchases": {
        indexLabel: "Мои покупки",
        analysis: "Анализ покупок",
        transaction: "Транзакции",
      },
      clubs: {
        indexLabel: "Клубы по интересам",
      },
      contacts: {
        indexLabel: "Контактная информация",
      },
      "favorite-products": {
        indexLabel: "Любимый товар",
      },
    },
  }

  const getBreadcrumbItem = (routeMap, pathArray, index, itemsArray, path) => {
    if (index >= pathArray.length) return

    const label = routeMap[pathArray[index]]?.indexLabel || routeMap[pathArray[index]]
    itemsArray.push({
      url: "/" + path,
      label,
    })

    const nextIndex = index + 1
    const nextPath = path + "/" + pathArray[nextIndex]
    getBreadcrumbItem(routeMap[pathArray[index]], pathArray, nextIndex, itemsArray, nextPath)
  }

  useEffect(() => {
    const routes = []
    const parsedLocation = location.pathname.split("/").filter((path) => path !== "")
    getBreadcrumbItem(routeMap2, parsedLocation, 0, routes, parsedLocation[0])
    setRoutes(routes)
  }, [location.pathname])

  const breadcrumbItem = routes.map((item, index) => {
    return (
      <Link key={item.url} className="breadcrumb__item" to={item.url}>
        {item.label}
        {routes.length - 1 !== index && <span className="breadcrumb__deliver"> / </span>}
      </Link>
    )
  })

  return <nav className="breadcrumb">{breadcrumbItem}</nav>
}

export default Breadcrumb
