import React, { useState } from "react"
import styles from "./ProductCard.module.scss"

//components
import PopUp from "@components/shared/popup/PopUp"
import ProductMoreInfo from "@components/cabinet/popup-content/ProductMoreInfo"

//assets
import PrductImage from "@assets/images/temp/product.png"

const ProductCard = ({ isCoupon, buttonText, isActiveButton, disabled }) => {
  const [isMorePopUpOpen, setMorePopUpState] = useState(false)

  const defaultClasses = isActiveButton
    ? `${styles["button-buy"]} button button--red`
    : `${styles["button-buy"]} button button--red button--red-light`

  const AboutProductPopUp = () => {
    return (
      <PopUp onClose={() => setMorePopUpState(false)}>
        <ProductMoreInfo />
      </PopUp>
    )
  }

  const toggleMorePopUpState = () => {
    setMorePopUpState((old) => !old)
  }

  const CouponDescription = !isCoupon ? (
    <div className={`${styles["coupon-description"]}`}>
      <div className={`${styles["coupon-description__wrapper"]} flex`}>
        <div className={styles["coupon-description__left"]}>
          <p className={styles.discount}>-70%</p>
          <p className={styles.date}>до 15.07.2020</p>
        </div>
        <div className={styles["coupon-description__right"]}>
          <div className={styles.bonus}>300 Б</div>
        </div>
      </div>
      <div className={`${styles.actions} flex`}>
        <button className={styles["button-readmore"]} onClick={() => toggleMorePopUpState()}>
          Подробнее о товаре
        </button>
      </div>
    </div>
  ) : null

  return (
    <>
      <div className={`${styles["product-card"]} flex ${disabled ? styles.disabled : ""}`}>
        <h5 className={styles.title}>АЗОВСКАЯ Мармелад, 200 г</h5>
        <div className={styles["image__wrapper"]}>
          <img className={styles.image} src={PrductImage} />
        </div>
        {CouponDescription}
        <button className={defaultClasses}>{buttonText}</button>
      </div>
      {isMorePopUpOpen && <AboutProductPopUp />}
    </>
  )
}

export default ProductCard
