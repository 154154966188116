import React from "react"

//components
import ContactsForm from "@components/cabinet/contacts/ContactsForm"

const ContactsPage = () => {
  return <ContactsForm />
}

export default ContactsPage
