import React from "react-dom"

export default function SearchBar(){
  
  return(
       <div className="search__wrapper">
          <input  className='search__input' type="text" placeholder='Начните вводить...'/>
          <button className='search__button'></button>
        </div>
  )
}